<template>
  <el-dialog
          :visible.sync="dialogVisible"
          width="620px"
          @close="dialogClose"
          @open="dialogOpen"
  >
    <div slot="title">
      <span style="font-weight: 700;font-size: 18px">{{ form.id ? '编辑' : '新增' }}</span>
      <el-tooltip content="此处填写的cookie信息将在网页前端展示，当访客在网页上进行授权设置时，访客可以查看到cookie的以下信息" placement="top">
        <i class="el-icon-info" />
      </el-tooltip>
    </div>
    <el-form
            v-if="dialogVisible"
            ref="form"
            v-loading="loading"
            :model="form"

            label-width="120px"
    >
      <el-form-item
              label="名称"
              v-input-filter
              prop="cookieName"
              :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
                v-model="form.cookieName"
                maxlength="100"
        />
      </el-form-item>
      <el-form-item label="类型"  prop="purposeId"
                    :rules="[ { required: true, message: '类型不能为空', trigger: 'change' }]">
        <el-select v-model="form.purposeId">
          <el-option v-for="(item,index) in cookieTypeList" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
              label="持有人"
              prop="holder"
              :rules="[ { required: true, message: '持有人不能为空', trigger: 'change' }]"
      >
        <el-input
                v-input-filter
                v-model="form.holder"
                placeholder="请输入持有人"
        />
      </el-form-item>
      <el-form-item
              label="Host"
              prop="host"
              :rules="[ { required: true, message: 'Host不能为空', trigger: 'change' }]"
      >
        <el-input
                v-input-filter
                v-model="form.host"
                placeholder="请输入HOST"
        />
      </el-form-item>
      <el-form-item
              label="有效期"
              prop="effectiveTime"
              :rules="[ { required: true, message: '有效期不能为空', trigger: 'change' }]"
      >
        <el-input
                v-input-filter
                v-model="form.effectiveTime"
                placeholder="请输入有效期"
        />
      </el-form-item>
      <el-form-item
              label="隐私政策链接"
              prop="PrivacyPolicyUrl"
              :rules="[ { required: true, message: '隐私政策链接不能为空', trigger: 'change' }]"
      >
        <el-input
                v-input-filter
                v-model="form.PrivacyPolicyUrl"
                placeholder="请输入隐私政策链接"
        />
      </el-form-item>
      <el-form-item
              label="描述"
      >
        <el-input
                v-input-filter
                v-model="form.remark"
                placeholder="请输入描述"
        />
      </el-form-item>
    </el-form>
    <div
            slot="footer"
            class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
              type="primary"
              :disabled="loading"
              @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { success, error } from '@core/utils/utils'
 import { ListCookieType,saveOrUpdateCoookie } from '@/api/cpm/cpm'
  export default {
    data() {
      return {
        users: [],
        loading: false,
        dialogVisible: false,
        form: {
          holder: '',
          PrivacyPolicyUrl: '',
          cookieName: '',
          effectiveTime: '',
          host: '',
          remark: '',
          purposeId: '',
          id: '',
        },
        cookieTypeList: []
      }
    },
    methods: {
      listCookieType(){
        ListCookieType().then(res=>{
           const resData = res.data
          if(resData.code === 0){
            this.cookieTypeList = resData.data
          }
        })
      },
      dialogOpen() {
        this.listCookieType()
      },
      dialogClose() {
        this.form = {
          holder: '',
          PrivacyPolicyUrl: '',
          cookieName: '',
          effectiveTime: '',
          host: '',
          remark: '',
          purposeId: '',
          id: '',
        }
        this.$refs.form.resetFields()
      },
      // eslint-disable-next-line vue/no-dupe-keys
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            if(!this.form.id){
              delete this.form.id
            }
            saveOrUpdateCoookie(this.form).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>
