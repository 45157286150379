<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="名称"
          :show-overflow-tooltip="true"
          prop="title"
        />
        <el-table-column
          prop="language"
          :show-overflow-tooltip="true"
          label="语言"
        />
        <el-table-column
          prop="describe"
          :show-overflow-tooltip="true"
          label="描述"
        />
        <el-table-column
          width="100"
          prop="createTime"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          prop="updateTime"
          label="修改时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="copyPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row.id,1)"
            >
              <el-tooltip
                content="复制"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document-copy" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="del(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <PageQuery
        v-show="total>0"
        :total="total"
        :cache-name="'listQuery14'"
        :update-state="'UPDATE_LISTQUERY14'"
        :list-query.sync="listQuery"
        :init-list-query.sync="initListQuery"
        @pagination="getList"
      />
    </b-card>
    <CookiesEdit
      ref="cookiesEdit"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
import CookiesEdit from '@/views/cookies/CookiesEdit'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import { ListCookieTheme, DelCookieTheme } from '@/api/cpm/cpm'

export default {
  components: {
    pagination,
    CookiesEdit,
  },

  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
    }
    return {
      savePerm: findBtnPerm('cookies-template-2'),
      updatePerm: findBtnPerm('cookies-template=4'),
      delPerm: findBtnPerm('cookies-template-5'),
      copyPerm: findBtnPerm('cookies-template-3'),
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'cookies-template-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'cookies-template-4')
      this.delPerm = verifyBtnPerm(btnPerms, 'cookies-template-5')
      this.copyPerm = verifyBtnPerm(btnPerms, 'cookies-template-3')
    })
    const cacheQuery = this.$store.state.pageQuery.listQuery14
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    del(productId) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          DelCookieTheme({ id: productId })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    saveDialogShow(type, id, copy) {
      // this.$refs.cookiesEdit.dialogVisible = true
      this.$router.push({ path: '/cookies-template-save' })
      if (type === 2) {
        // this.$refs.cookiesEdit.form.id = id
        this.$router.push({
          path: '/cookies-template-save',
          query: {
            id,
            c: copy,
          },
        })
      }
    },
    detailDialogShow(productId) {
      this.$refs.productDetail.dialogVisible = true
      this.$refs.productDetail.productInfo.id = productId
    },
    getList() {
      this.loading = true
      ListCookieTheme(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
